:root {
  --light-color: #fffff0;
  --dark-color: #0c1117;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.App-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.App-content {
  background-color: var(--background-color);
}

.loader, .fade-in {
  transition: opacity 1s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-color);
}

.loader.visible, .fade-in.visible {
  opacity: 1;
}